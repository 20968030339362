//===================================================
// 1. Load, Init, Settings
//===================================================
import axios from "axios";
import React, { useState , useEffect, useCallback } from "react";
import countries from "world-countries";

// Material UI Components
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Select,
  MenuItem,
  Snackbar,
  Alert
} from "@mui/material";

// Styling
import { Box } from "@mui/system";
import { useStyles } from "./index.css";

//===================================================
// 2. Main Component
//===================================================
const HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY;

const CustomSearchLocation = (props) => {
  const classes = useStyles();

  // State
  const [addressInput, setAddressInput] = useState("");
  const [placePredictions, setPlacePredictions] = useState([]);
  const [showManualInput, setShowManualInput] = useState(false);
  const [manualAddress, setManualAddress] = useState({
    fullAddress: "",
    street: "",
    suburb: "",
    state: "",
    country: "Australia",
    zipcode: "",
    longitude: "",
    latitude: "",
  });

  // Fetch address predictions from HERE Maps API
  const fetchPlacePredictions = async (query, selectedCountry) => {
    if (!query) {
      setPlacePredictions([]);
      return;
    }
    try {
      const response = await axios.get(
        `https://autocomplete.search.hereapi.com/v1/autocomplete`,
        {
          params: {
            q: query,
            apiKey: HERE_API_KEY,
            in: `countryCode:${selectedCountry}`,
            limit: 5,
            lang: "en",
          },
        }
      );
      setPlacePredictions(response.data.items || []);
    } catch (error) {
      console.error("Error fetching autocomplete results:", error);
    }
  };

  // Select address from HERE Maps Autocomplete
  const onSelectAddress = async (item) => {
    try {
      const response = await axios.get(
        `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(
          item.title
        )}&apiKey=${HERE_API_KEY}`
      );

      if (!response.data.items.length) {
        console.error("No location details found");
        return;
      }

      const locationData = response.data.items[0];
      const { position, address } = locationData;

      const formattedAddress = {
        fullAddress: address.label || "",
        street: address.street || "",
        suburb: address.district || "",
        state: address.state || "",
        zipcode: address.postalCode || "",
        country: address.countryName || "Australia",
        longitude: position.lng || "",
        latitude: position.lat || "",
      };

      if (!formattedAddress.street || !formattedAddress.zipcode || !formattedAddress.suburb || !formattedAddress.state) {
        console.error("Address validation failed:", formattedAddress);
        return;
      }

      setAddressInput(address.label);
      setPlacePredictions([]);
      setShowManualInput(false);
      showNextStep(formattedAddress, props);
    } catch (error) {
      console.error("Error fetching geolocation:", error);
    }
  };

  return showMain({
    ...props,
    classes,
    addressInput,
    setAddressInput,
    placePredictions,
    fetchPlacePredictions,
    onSelectAddress,
    showManualInput,
    setShowManualInput,
    manualAddress,
    setManualAddress,
    setPlacePredictions,
  });
};

//===================================================
// 3. Export
//===================================================
export default CustomSearchLocation;

//===================================================
// 4. Functions
//===================================================
const showMain = (props) => {
  const { type, setPlacePredictions } = props;
  return type === "home"
    ? ShowSearchForHomePage({ ...props, setPlacePredictions })
    : ShowSearchForForm({ ...props, setPlacePredictions });
};

// **Form for entering or searching addresses**
const ShowSearchForForm = (props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const {
    addressInput,
    setAddressInput,
    placePredictions,
    fetchPlacePredictions,
    onSelectAddress,
    showManualInput,
    setShowManualInput,
    manualAddress,
    setManualAddress,
    selectedCountry,
    setPlacePredictions,
  } = props;

  const handleSaveManualAddress = () => {
    if (!manualAddress.street || !manualAddress.suburb || !manualAddress.state || !manualAddress.zipcode) {
      alert("Please fill in all required fields.");
      return;
    }

    const fullManualAddress = `${manualAddress.street}, ${manualAddress.suburb}, ${manualAddress.state}, ${manualAddress.zipcode}, Australia`;
    setManualAddress((prev) => ({ ...prev, fullAddress: fullManualAddress }));

    showNextStep(manualAddress, props);
    setShowManualInput(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAddressInput(value);

    if (!selectedCountry) {
      setOpenSnackbar(true);
      setIsDisabled(true);
      setAddressInput("");
    } else {
      fetchPlacePredictions(value, selectedCountry);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      setIsDisabled(false);
      setAddressInput("");
      setOpenSnackbar(false);
      setPlacePredictions([]);
    }
  }, [selectedCountry, setAddressInput, setPlacePredictions]);

  return (
    <Container align="left" sx={{ width: "100%" }}>

      <Box sx={{ zIndex: "1051 !important", mt: 2 }}>
        {!showManualInput ? (
          <>
            <TextField
              sx={{ backgroundColor: "white", borderRadius: "15px" }}
              type="text"
              onChange={handleInputChange}
              value={addressInput}
              placeholder="Search Address"
              fullWidth
              disabled={isDisabled}
            />

            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={() => setOpenSnackbar(false)} severity="warning" sx={{ width: "100%" }}>
                Please choose a country first.
              </Alert>
            </Snackbar>

            {placePredictions.length > 0 && (
              <Paper sx={{ p: 2, my: 2 }}>
                <Typography variant="caption" sx={{ color: "gray", fontWeight: "bold" }}>
                  Select location
                </Typography>
                {placePredictions.map((item, index) => (
                  <Typography
                    key={index}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "#4169e1",
                        borderRadius: "10px",
                      },
                      mt: 1,
                      p: 1,
                    }}
                    onClick={() => onSelectAddress(item)}
                  >
                    {item.title}
                  </Typography>
                ))}
              </Paper>
            )}
            <Button onClick={() => setShowManualInput(true)}>Enter Address Manually</Button>
          </>
        ) : (
          <>
            <Typography variant="h6">Enter Address Manually</Typography>
            <TextField label="Street" fullWidth required onChange={(e) => setManualAddress({ ...manualAddress, street: e.target.value })} />
            <TextField label="Suburb" fullWidth required onChange={(e) => setManualAddress({ ...manualAddress, suburb: e.target.value })} />
            <TextField label="State" fullWidth required onChange={(e) => setManualAddress({ ...manualAddress, state: e.target.value })} />
            <TextField label="Zip Code" fullWidth required onChange={(e) => setManualAddress({ ...manualAddress, zipcode: e.target.value })} />
            <Button onClick={handleSaveManualAddress}>Save Address</Button>
            <Button onClick={() => setShowManualInput(false)}>Cancel</Button>
          </>
        )}
      </Box>
    </Container>
  );
};

// **ShowSearchForHomePage Function**
const ShowSearchForHomePage = (props) => {
  const [selectedCountry, setSelectedCountry] = useState("");

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Where should the Cleaning Service be?
      </Typography>
      <SelectCountry selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} sx={{mb: 2}}/>
      <ShowSearchForForm {...props} selectedCountry={selectedCountry} />
    </Box>
  );
};

// **SelectCountry Function**

const formattedCountries = countries.map((country) => ({
  name: country.name.common,
  cca3: country.cca3,
  countryCode: country.cca2,
  flag: `https://flagcdn.com/w40/${country.cca2.toLowerCase()}.png`,
}));

const SelectCountry = ({ selectedCountry, setSelectedCountry }) => {
  const [loading, setLoading] = useState(false); // No API call, so no need for loading state

  const fetchUserLocation = useCallback(async (latitude, longitude) => {
    try {
      const response = await axios.get(
        "https://revgeocode.search.hereapi.com/v1/revgeocode",
        {
          params: {
            at: `${latitude},${longitude}`,
            apiKey: HERE_API_KEY,
          },
        }
      );

      const userCountryName = response.data.items?.[0]?.address?.countryName;
      const matchedCountry = formattedCountries.find(
        (c) => c.name.toLowerCase() === userCountryName?.toLowerCase()
      );

      if (matchedCountry) setSelectedCountry(matchedCountry.cca3);
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  }, [formattedCountries, setSelectedCountry]);

  useEffect(() => {
    if (loading || selectedCountry) return;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => fetchUserLocation(position.coords.latitude, position.coords.longitude),
        (error) => console.error("Error getting geolocation:", error),
        { enableHighAccuracy: true }
      );
    }
  }, [loading, selectedCountry, fetchUserLocation]);

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography variant="h6" sx={{ minWidth: 180 }}>
        Choose a Country:
      </Typography>
      <Select
        id="country-select"
        value={selectedCountry || ""}
        onChange={(e) => setSelectedCountry(e.target.value)}
        displayEmpty
        sx={{ minWidth: 250 }}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 288, overflowY: "auto", width: 250, mt: 0.5 },
          },
        }}
        renderValue={(selected) => {
          if (!selected) return "Select a Country";

          const selectedCountryObj = formattedCountries.find((country) => country.cca3 === selected);
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {selectedCountryObj?.flag && (
                <img
                  src={selectedCountryObj.flag}
                  alt={selectedCountryObj.name}
                  style={{ width: 20, height: 15 }}
                />
              )}
              <span>{selectedCountryObj?.name}</span>
            </Box>
          );
        }}
      >
        <MenuItem value="" disabled>
          Select a Country
        </MenuItem>
        {formattedCountries.map(({ cca3, name, flag }) => (
          <MenuItem key={cca3} value={cca3} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {flag && <img src={flag} alt={name} style={{ width: 20, height: 15 }} />}
            <span>{name}</span>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};


//===================================================
// 5. Actions
//===================================================
const showNextStep = (place, props) => {
  const { handleShowDateTime, setAddress, type } = props;
  if (type !== "form") {
    handleShowDateTime && handleShowDateTime();
  }
  setAddress && setAddress(place);
};
